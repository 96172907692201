import * as React from "react"
import { useEffect } from "react"
import Typography from "/src/components/ui/base/typography"
import Header from "/src/components/ui/base/layout/header"
import { useTranslation } from "react-i18next"
import "moment/locale/it"
import Section from "../../components/ui/base/Section"
import { createMarkup } from "../../util/tour"
import GatsbyImage from "gatsby-image"
import Button from "../../components/ui/base/Button"
import { BASE_URL, COURSES_URL } from "../../util/constants"
import Breadcrumbs from "../../components/ui/base/Breadcrumbs"
import CoachCard from "../../components/ui/base/CoachCard"
import classnames from "classnames"
import * as styles from "./index.module.scss"
import { GatsbySeo } from "gatsby-plugin-next-seo/src/meta/gatsby-seo"
import { FAQJsonLd, ProductJsonLd } from "gatsby-plugin-next-seo"
import moment from "moment"
import { Col, Container, Row } from "react-bootstrap"
import { graphql } from "gatsby"
import Faq from "../../components/ui/base/Faq"
import GallerySection from "../../components/ui/extended/GallerySection"
import ReviewCard from "../../components/ui/base/ReviewCard"

const CoursePage = ({ data }) => {
  const { t, i18n: l } = useTranslation()

  const course = data.strapiCourse
  const courseLink = `${BASE_URL[l.language]}/${COURSES_URL[l.language]}/${
    course?.slug
  }/`
  const courseImage = course?.cover?.localFile?.childImageSharp.gatsbyImageData

  const getCourseBreadCrumbs = () => {
    return [
      {
        name: "WeShoot",
        path: "/",
      },
      {
        name: "Corsi di Fotografia",
        path: `/${COURSES_URL[l.language]}/`,
      },
      {
        name: course?.title,
        path: `/${COURSES_URL[l.language]}/${course?.slug}/`,
      },
    ]
  }

  const getCoursePictures = () => {
    const picturesArray = []
    course.pictures &&
      course.pictures?.map(picture => {
        picture.image.map(image => {
          picturesArray.push({
            localFile: {
              childImageSharp: {
                ...image.localFile.childImageSharp,
                thumbAlt: image.title,
                title: image.title,
              },
            },
          })
        })
        return picture
      })
    return picturesArray
  }
  const coursePictures = getCoursePictures()

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({ ecommerce: null })
      window.dataLayer.push({
        event: "view_item",
        ecommerce: {
          currencyCode: "EUR",
          items: [
            {
              name: course?.seo?.metaTitle,
              id: course?.strapiId,
              price: course?.price,
              brand: "WeShoot",
              category: "Corsi di fotografia",
            },
          ],
        },
      })

      if (typeof window !== "undefined") {
        if (window.fbq != null) {
          window.fbq("track", "ViewContent", {
            value: course?.price,
            currency: "EUR",
            content_type: "product",
            content_ids: course?.strapiId,
          })
        }
      }
    }
  }, [])

  return (
    <>
      <GatsbySeo
        htmlAttributes={{ lang: l.language }}
        title={course?.seo?.metaTitle}
        description={course?.seo?.metaDescription}
        canonical={courseLink}
        openGraph={{
          url: courseLink,
          type: "website",
          title: course?.seo?.metaTitle,
          description: course?.seo?.metaDescription,
          images: [
            {
              url: course?.seo?.shareImage.url,
              alt: course?.seo?.metaTitle,
            },
          ],
          site_name: "WeShoot",
        }}
      />

      <ProductJsonLd
        productName={course?.seo?.metaTitle}
        description={course?.seo?.metaDescription}
        brand="WeShoot"
        images={course?.seo?.shareImage.url}
        sku={course?.slug}
        mpn={course?.id}
        aggregateRating={{
          ratingValue: "5",
          reviewCount: 3,
        }}
        offers={{
          price: course?.price,
          priceCurrency: "EUR",
          priceValidUntil: moment().format("YYYY-MM-DD"),
          itemCondition: "NewCondition",
          availability: "OnlineOnly",
          url: courseLink,
          seller: {
            name: "WeShoot",
          },
        }}
      />

      <Header
        theme="dark"
        img={course?.image?.localFile.childImageSharp.gatsbyImageData}
      >
        <Container fluid="xl">
          <Row className="align-items-center">
            <Col lg={12}>
              <div className={classnames([styles.header])}>
                <Typography className="animated fadeInDown" variant="heading1">
                  {course?.title}
                </Typography>
                <Typography className="animated fadeInDown" variant="heading2">
                  {course?.excerpt}
                </Typography>
                <Breadcrumbs elements={getCourseBreadCrumbs()} />

                <Button
                  variant={"link"}
                  onClick={() => {
                    window.location = `${course.url}`
                  }}
                >
                  Acquista ora a soli €{course?.price}
                </Button>
              </div>
            </Col>
          </Row>
        </Container>
      </Header>

      <div className={classnames([styles.img_container])}>
        {courseImage && (
          <GatsbyImage
            alt={"Viaggi Fotografici"}
            loading="eager"
            className={classnames([styles.image_background])}
            image={courseImage}
          />
        )}
      </div>

      <Section
        variant={"white"}
        title={"Piacere, sono il tuo insegnante 👋"}
        subtitle={`Mi chiamo ${course?.teacher.firstName} e ti faró il ${course?.title}`}
      >
        <CoachCard coach={course?.teacher} />
      </Section>

      <Section variant={"white"}>
        <GatsbyImage
          image={course?.cover?.localFile.childImageSharp.gatsbyImageData}
        />
        <div
          className="mb-5"
          dangerouslySetInnerHTML={createMarkup(course.presentation)}
        />
        <Button
          variant={"link"}
          onClick={() => {
            window.location = `${course.url}`
          }}
        >
          Acquista ora a soli €{course?.price}
        </Button>
      </Section>

      {coursePictures && coursePictures.length > 0 && (
        <Section
          id={"gallery"}
          className={classnames([styles.gallery])}
          title={"Alcune delle mie foto"}
          subtitle={
            "Nei miei corsi di fotografia ti insegneró a fare fotografie come queste"
          }
        >
          <GallerySection pictures={coursePictures} />
        </Section>
      )}

      {course.reviews.length > 0 && (
        <Section
          className={classnames([styles.reviews])}
          id={"reviews"}
          variant={"grey"}
          title={t("ui.pages.tour.reviews.title")}
          subtitle={t("ui.pages.tour.reviews.subtitle")}
        >
          {course.reviews?.map(review => {
            return <ReviewCard key={review.id} review={review} />
          })}
        </Section>
      )}

      {course?.faqs && course?.faqs.length > 0 && (
        <Section
          variant={"grey"}
          title={`Domande piú frequenti su ${course.title}`}
        >
          {course.faqs?.map(q => {
            return <Faq faq={q} />
          })}
          <FAQJsonLd questions={course.faqs} />
        </Section>
      )}
    </>
  )
}

export default CoursePage

export const query = graphql`
  query CourseQuery($slug: String!) {
    strapiCourse(slug: { eq: $slug }) {
      title
      presentation
      url
      excerpt
      slug
      pictures {
        image {
          localFile {
            ...ImageFragment
          }
          alternativeText
          caption
          name
        }
      }
      reviews {
        created_at
        description
        title
        rating
        user
      }
      image {
        localFile {
          ...ImageFragment
        }
      }
      faqs {
        answer
        id
        question
      }
      price
      cover {
        caption
        alternativeText
        localFile {
          ...ImageFragment
        }
      }
      teacher {
        bio
        profilePicture {
          localFile {
            ...ImageFragment
          }
        }
        username
        lastName
        firstName
      }
      seo {
        shareImage {
          caption
          url
        }
        metaDescription
        metaTitle
      }
    }
  }
  fragment ImageFragment on File {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH)
      thumb: gatsbyImageData(width: 270, height: 270, placeholder: BLURRED)
      full: gatsbyImageData(layout: FULL_WIDTH)
    }
  }
`
